<template>
  <!-- form name is part of analytics tracking - do not change!! -->
  <v-form ref="form" @submit.prevent="contact" class="quick-contact" :name="formName">
    <slot name="title"/>
    <slot name="hidden-fields"/>

    <input type="hidden" name="source" :value="$route.name">

    <v-row :no-gutters="!sideBySide">
      <v-col cols=12 :sm="sideBySide ? 4 : 12" :md="sideBySide ? 3 : 12" class="no-padding-bottom">
        <v-text-field v-model="formData.first_name"
                      :label="$t('contact.first_name_label')"
                      outlined
                      background-color="#fff"
                      dense
                      ref="first_name"
                      name="first_name"
                      :rules="[rules.required]"/>
      </v-col>

      <v-col cols=12 :sm="sideBySide ? 4 : 12" :md="sideBySide ? 3 : 12" class="no-padding-bottom">
        <v-text-field v-model="formData.last_name"
                      :label="$t('contact.last_name_label')"
                      outlined
                      background-color="#fff"
                      dense
                      ref="last_name"
                      name="last_name"
                      :rules="[rules.required]"/>
      </v-col>

      <v-col cols=12 :sm="sideBySide ? 4 : 12" :md="sideBySide ? 3 : 12" class="no-padding-bottom">
        <v-text-field v-model="formData.phone"
                      :label="$t('contact.phone_label')"
                      outlined
                      background-color="#fff"
                      dense
                      name="phone"
                      :rules="[rules.required, rules.number]"/>
      </v-col>

      <v-col cols=12 :sm="sideBySide ? 4 : 12" :md="sideBySide ? 3 : 12" class="no-padding-bottom">
        <v-text-field v-model="formData.email"
                      :label="$t('contact.email_label')"
                      outlined
                      background-color="#fff"
                      dense
                      name="email"
                      :rules="[rules.required, rules.email]"/>
      </v-col>
    </v-row>

    <v-row class="no-margin-top" v-if="$slots['custom-fields']">
      <v-col>
        <slot name="custom-fields"/>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols=12 offset=0 :offset-md="sideBySide ? 4 : 0" :md="sideBySide ? 4 : 12" :sm="sideBySide ? 6 : 12" :offset-sm="sideBySide ? 3 : 0" class="text-center">
        <v-btn block color="secondary" type="submit" :disabled="btnDisabled">{{ buttonText }}</v-btn>
        <vue-markdown class="font-10 margin-top-10">{{ $t('quick_contact.tos', { button_text: buttonText }) }}</vue-markdown>
      </v-col>
    </v-row>

    <slot name="content"/>

    <v-snackbar v-model="snackbar" :color="snackbarColor">
      <div v-html="snackbarMessage"/>
    </v-snackbar>
  </v-form>
</template>

<script>
import { Api } from '@/common/services/api-service.js'
import rules from '@/common/mixins/validations-rules-mixin.js'

export default {

  name: 'QuickContact',

  props: {
    inline: Boolean,
    // page: String,
    message: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: function () { return this.$t('quick_contact.btn') }
    },
    post: {
      type: String,
      default: '/contacts'
    },
    formName: {
      type: String,
      default: function () { return `${this.$route.name}_contact` }
    }
  },

  data () {
    return {
      formData: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        tracked: false
      },
      btnDisabled: false,
      snackbarColor: '',
      snackbarMessage: '',
      snackbar: false
    }
  },

  mixins: [rules],

  watch: {
    formData: {
      handler () {
        if (this.tracked) return
        this.$mixpanel.track('contact_form', { action: 'engaged', page: this.$route.name, form: this.formData })
        this.tracked = true
      },
      deep: true
    }
  },

  computed: {
    sideBySide () {
      return this.$vuetify.breakpoint.smAndUp && this.inline
    }
  },

  methods: {
    showSnackbar (type) {
      this.snackbarMessage = this.$t(`contact.${type}_message`)
      this.snackbarColor = type
      this.snackbar = true
    },

    async contact () {
      if (this.$refs.form.validate()) {
        this.btnDisabled = true
        const data = JSON.stringify(Object.fromEntries(new FormData(this.$refs.form.$el)))
        try {
          await Api.post(this.post, data)
          this.showSnackbar('success')
          this.$emit('success')
          this.btnDisabled = false
        } catch (e) {
          console.error(e)
          this.showSnackbar('error')
          this.btnDisabled = false
          this.$emit('error', e)
        }
        this.$mixpanel.track('contact_form', { action: 'submit', page: this.$route.name, form: this.formData })
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss">

  form.quick-contact {
    padding: 20px 15px;
    border: 1px solid var(--v-bright-darken4);
    border-radius: 4px;

    p {
      margin: 0;
    }
  }

</style>

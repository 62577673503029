const mixin = {
  computed: {
    fullAddress () {
      const adrs = this.estate.address
      if (adrs.city && adrs.street) {
        return `${adrs.street || ''} ${adrs.number || ''}, ${adrs.city || ''}`
      }
      return adrs.general_address
    },

    location () {
      const adrs = this.estate.address
      if (adrs.location) {
        return {
          cords: {
            lng: adrs.location.lon,
            lat: adrs.location.lat
          },
          address: adrs.general_address
        }
      }
    }
  }
}

export default mixin

// global common mixins
import Vue from 'vue'

export default Vue.mixin({
  methods: {
    searchQueryToObject (query) {
      const searchParams = new URLSearchParams(query)

      const size = searchParams.has('size') ? { min: parseInt(searchParams.get('size').split(',')[0]), max: parseInt(searchParams.get('size').split(',')[1]) } : null
      const rooms = searchParams.has('rooms') ? { min: parseInt(searchParams.get('rooms')), max: parseInt(searchParams.get('rooms')) + 1 } : null

      return {
        free_text: searchParams.get('search') || '',
        category: searchParams.has('types') ? searchParams.get('types').split(',') : null,
        deal_type: searchParams.get('dealType') || null,
        features: searchParams.get('features') || null,
        size,
        rooms,
        sector: null,
        price: {
          min: searchParams.get('price') - (searchParams.get('price') / 5) || 0,
          max: parseInt(searchParams.get('price')) + parseInt((searchParams.get('price') / 5)) || 999999999
        },
        address_search_dto: searchParams.get('address') || null
      }

      // return Object.keys(params).reduce((acc, key) => {
      //   if (params[key]) {
      //     acc[key] = params[key]
      //   }
      //   return acc
      // }, {})
    },

    buildCldImageUrl (id = null, { crop = 'thumb', height = '', width = '', overlay = {}, gravity = '', opacity = '', background = '' } = {}) {
      if (!id) return 'undefined image'
      return this.$cloudinary.url(id, {
        transformation: [{ crop, height, width, gravity, opacity, background }, overlay] })
    },

    getRandom (min = 0, max = 0) {
      min = parseInt(min)
      max = parseInt(max)
      return Math.floor(Math.random() * (max - min) + min)
    }
  }
})

// query () {
//   let query = {}

//   if (this.freeSearchParams.freeText) {
//     query.freeText = this.freeSearchParams.freeText
//   }

//   if (this.price) {
//     query.price = this.price
//   }

//   if (this.sizeRange) {
//     query.size = {
//       min: parseInt(this.sizeRange[0]) || 0,
//       max: parseInt(this.sizeRange[1]) || 0
//     }
//   }

//   if (this.rooms) {
//     query.rooms = {
//       min: parseInt(this.rooms),
//       max: parseInt(this.rooms) + 1
//     }
//   }

//   if (this.typesFilter.length) {
//     query.types = this.typesFilter
//   }

//   if (this.featuresFilter.length) {
//     query.features = this.featuresFilter.toString()
//   }

//   if (this.city && this.initialSearch) {
//     const address = {}
//     address.city = this.city

//     if (this.street) {
//       address.street = this.street
//     }

//     if (this.state) {
//       address.state = this.state
//     }

//     if (Object.keys(address).length) {
//       query.address = address
//     }
//   }

//   query.deal_type = this.freeSearchParams.dealType // deal_type alyaws has a value

//   return query
// }

<template>
  <v-row align="center" class="how-it-works colored-section" no-gutters>
    <v-col class="how-it-works-content" v-intersect.once="onIntersect">
      <transition name="slide-fade-down">
        <div class="section-header" v-if="isIntersected">
          <h2 class="font-36 font-600"> {{ $t('how_it_works.title') }} </h2>
          <vue-markdown> {{ $t('how_it_works.subtext') }} </vue-markdown>
        </div>
      </transition>

      <v-row class="hows" no-gutters v-intersect.once="onIntersect">
        <transition name="slide-fade">
          <v-col cols="12" md="4" v-if="isIntersected">
            <v-row  justify="center">
              <v-img :src="require('@/assets/images/home-process-1.png')" max-width="250px"/>
            </v-row>
            <v-col>
              <h5> {{ $t('how_it_works.how_1_title') }} </h5>
              <p> {{ $t('how_it_works.how_1_text') }} </p>
            </v-col>
          </v-col>
        </transition>
        <transition name="slide-fade-up">
          <v-col cols="12" md="4" v-if="isIntersected">
            <div class="dots"/>
            <v-row  justify="center">
              <v-img :src="require('@/assets/images/home-process-2.png')" max-width="250px"/>
            </v-row>
            <v-col>
              <h5> {{ $t('how_it_works.how_2_title') }} </h5>
              <p> {{ $t('how_it_works.how_2_text') }} </p>
            </v-col>
          </v-col>
        </transition>
        <transition name="slide-fade-reverse">
          <v-col cols="12" md="4" v-if="isIntersected">
            <div class="dots"/>
            <v-row  justify="center">
              <v-img :src="require('@/assets/images/home-process-3.png')" max-width="250px"/>
            </v-row>
            <v-col>
              <h5> {{ $t('how_it_works.how_3_title') }} </h5>
              <p> {{ $t('how_it_works.how_3_text') }} </p>
            </v-col>
          </v-col>
        </transition>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import intersecting from '@/common/mixins/intersecting-mixin'

export default {

  name: 'HowItWorks',

  mixins: [intersecting]

}
</script>

<style lang="scss">

  .how-it-works {
    padding: 0 20px 50px;
    overflow: hidden;

    p {
      margin: 13px auto 50px;
      text-align: center;
      color: var(--v-secondary-lighten3);
    }

    .section-header {
      text-align: center;

      p {
        font-size: $font-md;
        width: min(85vw, 830px);
      }
    }

    .how-it-works-content {
      max-width: 1264px;
      margin: auto;

      h2 {

        @include sm-and-down {
          margin: 20px 0;
        }
      }
    }

    .dots {
      position: relative;

      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 3px;
        background: url('~@/assets/images/dots.svg') no-repeat center;

        @include md-only {
          position: absolute;
          left: 87.5%;
          top: 105px;
          max-width: 85px;
        }

        @include lg-and-up {
          position: absolute;
          left: 85%;
          top: 105px;
          max-width: 124px;
        }
      }

      @include sm-and-down {
        margin-bottom: 20px;
      }
    }

    .hows {

      h5 {
        margin: 14px 0 0;
        text-align: center;
        font-size: $font-md;
      }

      p {
        max-width: 250px;
        font-size: $font-sm;

        @include xs-only {
          font-size: $font-md
        }
      }
    }
  }

</style>

<template>
  <router-view/>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'

export default {
  name: 'App',

  metaInfo () {
    return {
      htmlAttrs: {
        lang: this.locale.lang,
        dir: this.locale.dir
      },
      titleTemplate: (titleChunk) => {
        return titleChunk ? `${titleChunk} | Rbns` : this.$t('meta.global.title')
      },
      meta: [
        {
          property: 'og:title',
          content: this.$t('meta.global.title'),
          template: chunk => `${chunk} | Rbns`,
          vmid: 'og:title'
        },
        {
          property: 'og:description',
          content: this.$t('meta.global.description'),
          template: chunk => `${chunk} | Rbns`,
          vmid: 'og:description'
        },
        {
          property: 'og:type',
          content: 'website',
          vmid: 'og:type'
        },
        {
          property: 'og:locale',
          content: this.locale.lang,
          vmid: 'og:locale'
        },
        {
          property: 'og:locale:alternate',
          content: this.locale.lang === 'he' ? 'en' : 'he',
          vmid: 'og:locale:alternate'
        },
        {
          property: 'og:site_name',
          content: 'Rbns',
          vmid: 'og:site_name'
        },
        {
          property: 'og:image',
          content: require('@/assets/images/logo-full.png'),
          vmid: 'og:image'
        },
        {
          name: 'description',
          content: this.$t('meta.global.description'),
          template: chunk => `${chunk} | Rbns`,
          vmid: 'description'
        },
        {
          name: 'keywords',
          content: this.$t('meta.global.keywords'),
          template: chunk => `${chunk}, Rbns`,
          vmid: 'keywords'
        },
        {}
      ]
    }
  },

  data () {
    return {
    }
  },

  computed: {
    ...mapGetters(['locale'])
  }
}

</script>

<style lang='scss'>

</style>

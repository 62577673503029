<template>
  <v-layout column class="estates-grid padding-sides-20">
    <div class="section-header">
      <h2 class="font-36 font-600 margin-bottom-10 text-center">
        {{ $t('home_page.grid_title') }}
      </h2>
      <div class="font-18">
        <vue-markdown>{{ $t('home_page.grid_subtitle') }}</vue-markdown>
      </div>
    </div>

    <v-flex class="grid-content" v-if="estates.length">
      <div class="grid" v-intersect="onIntersect">
        <transition name="slide-fade">
          <EstateBox :estate="estates[0]" v-if="isIntersected"/>
        </transition>
        <transition name="slide-fade-down">
          <EstateBox :estate="estates[1]" v-if="isIntersected"/>
        </transition>
        <transition name="slide-fade">
          <EstateBox :estate="estates[2]" v-if="isIntersected"/>
        </transition>
        <transition name="slide-fade-up">
          <EstateBox :estate="estates[3]" v-if="isIntersected"/>
        </transition>
        <transition name="slide-fade-reverse">
          <EstateBox :estate="estates[4]" v-if="isIntersected"/>
        </transition>
      </div>
    </v-flex>
    <Spacer height="50px"/>
  </v-layout >

</template>

<script type="text/javascript">
import EstateBox from '@/app/modules/estates/components/estate-box'
import intersecting from '@/common/mixins/intersecting-mixin'
import EstatesService from '@/app/modules/estates/estates-service'

export default {
  name: 'EstatesHomeGrid',

  components: {
    EstateBox
  },

  data () {
    return {
      estates: []
    }
  },

  mixins: [intersecting],

  methods: {
    async getEstates () {
      const res = await EstatesService.getEstatesByPromotePage({ limit: 15 })
      return res
    }
  },

  async created () {
    let estates = await this.getEstates()
    this.estates = estates.sort(() => 0.5 - Math.random()).slice(0, 5)
  }
}

</script>

<style lang='scss' scoped>

  .estates-grid {
    max-width: 1264px;

    .section-header {
      margin: 20px auto;

      @include sm-and-up {
        margin: 35px auto;
      }
    }

    .grid-content {
      // height: 65vh;
      overflow: hidden;

      @include sm-and-up {

        .grid {
          display: grid;
          grid-gap: 12px;
          margin-bottom: 50px;
        }

        @include lg-and-up {

          .grid {
            height: calc(80vh - 140px);
            // grid-template-rows: 50% 50%;
            grid-template-columns: 25% 1fr 25% 25%;
          }

          .estate-box {

            &:nth-child(1) {
              grid-column: 1 / span 2;
              grid-row: 1;
            }

            &:nth-child(2) {
              grid-column: 3;
              grid-row: 1;
            }

            &:nth-child(3) {
              grid-column: 1;
              grid-row: 2;
            }

            &:nth-child(4) {
              grid-column: 2 / span 2;
              grid-row: 2;
            }

            &:nth-child(5) {
              grid-column: 4;
              grid-row: 1 / span 2;
            }
          }
        }

        @include md-and-down {

          .grid {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 25% 1fr 25% 25%;
          }

          .estate-box {

            &:nth-child(1) {
              grid-column: 1;
              grid-row: 1 / span 2;
            }

            &:nth-child(2) {
              grid-column: 2;
              grid-row: 1;
            }

            &:nth-child(3) {
              grid-column: 1;
              grid-row: 3;
            }

            &:nth-child(4) {
              grid-column: 2;
              grid-row: 2 / span 2;
            }

            &:nth-child(5) {
              grid-column: 1 / span 2;
              grid-row: 4;
            }
          }
        }

        @include md-only {

          @media screen and (orientation: portrait) {

            .grid {
              height: calc(80vh - 90px);
            }
          }
        }

        @include sm-and-down {

          .grid {
            min-height: 100vh;
          }
        }
      }

      @include xs-only {

        .estate-box {
          margin-bottom: 12px;
        }
      }
    }

    p {
      color: $text_gray;
    }

    @include sm-and-up {
      padding: 0 100px;
    }
  }
</style>

import Config from './config.js'
import Vue from 'vue'
import Vuex from 'vuex'
// import estates from '@/app/modules/estates/estates-store'
import user from '@/app/modules/user/user-store'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    locale: Config.locale || { lang: 'en', dir: 'ltr' },
    HPEntered: false,
    isBot: false,
    cookiesApproval: Vue.$cookies.isKey('cookies_approval')
  },

  getters: {
    locale: state => state.locale,
    HPEntered: state => state.HPEntered,
    isBot: state => state.isBot,
    cookiesApproval: state => state.cookiesApproval
  },

  mutations: {
    SET_LOCALE (state, locale) {
      state.locale = locale
    },
    SET_HPENTERED (state) {
      state.HPEntered = true
    },
    SET_BOT (state, bot) {
      state.isBot = bot
    },
    SET_COOKIES_APPROVAL (state) {
      state.cookiesApproval = true
    }
  },

  actions: {
    setLocale ({ commit }, lang) {
      const locale = { lang, dir: lang === 'he' ? 'rtl' : 'ltr' }
      Vue.$cookies.set('locale', locale)
      commit('SET_LOCALE', locale)
    },
    setHPEntered ({ commit }) {
      commit('SET_HPENTERED')
    },
    setBot ({ commit }, bot) {
      commit('SET_BOT', bot)
    },
    setCookiesApproval ({ commit }) {
      Vue.$cookies.set('cookies_approval', true, -1)
      commit('SET_COOKIES_APPROVAL')
    }
  },

  modules: {
    // estates,
    user
  }
})

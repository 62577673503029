const routes = [
  {
    path: '/contact_us',
    name: 'contactUs',
    component: () => import(/* webpackChunkName: "static-a" */'./contact-us.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "static-a" */ './about')
  },
  {
    path: '/term_of_service',
    alias: '/tos',
    name: 'tos',
    component: () => import(/* webpackChunkName: "static-c" */'./tos'),
    meta: {
      hideAlbi: true
    }
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import(/* webpackChunkName: "static-c" */'./faq'),
    meta: {
      hideAlbi: true
    }
  },
  {
    path: '/work_with_us',
    name: 'workWithUs',
    component: () => import(/* webpackChunkName: "static-a" */'./work-with-us'),
    meta: {
      hideAlbi: true
    }
  },
  {
    path: '/mortgage',
    name: 'mortgage',
    component: () => import(/* webpackChunkName: "static-a" */'./mortgage')
  },
  {
    path: '/sale',
    name: 'sale',
    component: () => import(/* webpackChunkName: "static-a" */'./sale')
  },
  {
    path: '/buy',
    name: 'buy',
    component: () => import(/* webpackChunkName: "static-a" */'./buy')
  },
  {
    path: '/greece',
    name: 'greece',
    component: () => import(/* webpackChunkName: "static-a" */'./greece')
  },
  {
    path: '/cookies_policy',
    name: 'cookiesPolicy',
    component: () => import(/* webpackChunkName: "static-a" */'./cookies-policy'),
    meta: {
      hideAlbi: true
    }
  },
  {
    path: '/tama',
    name: 'tama',
    component: () => import(/* webpackChunkName: "static-a" */'./tama')
  },
  {
    path: '/family_economy',
    name: 'familyEconomy',
    component: () => import(/* webpackChunkName: "static-a" */'./family-economy')
  },
  {
    path: '/cyprus',
    name: 'cyprus',
    component: () => import(/* webpackChunkName: "static-a" */'./cyprus')
  },
  {
    path: '/accessibility',
    name: 'accessibility',
    component: () => import(/* webpackChunkName: "static-a" */'./accessibility')
  },
  {
    path: '/estate_guide',
    name: 'estateGuide',
    component: () => import(/* webpackChunkName: "static-a" */'./estate-guide'),
    meta: {
      hideAlbi: true
    }
  },
  {
    path: '/community',
    name: 'community',
    component: () => import(/* webpackChunkName: "static-a" */'./community'),
    meta: {
      hideAlbi: true
    }
  },
  {
    path: '/unsubscribe/:id',
    name: 'unsubscribe',
    component: () => import(/* webpackChunkName: "static-a" */'./unsubscribe'),
    meta: {
      hideAlbi: true
    }
  },
  {
    path: '/404',
    name: 'notFound',
    component: () => import(/* webpackChunkName: "home" */'./404')
  }
]

export default routes

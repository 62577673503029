import Config from '@/config/bl-config.js'
import Vue from 'vue'
import mixpanel from 'mixpanel-browser'

mixpanel.init(Config.mixpanelToken)
Vue.prototype.$mixpanel = mixpanel

if (process.env.NODE_ENV === 'development') {
  mixpanel.disable()
}

<template>
  <main v-scroll="onScroll">
    <section id="home_header">
      <HomeHeader/>
      <scroll-to-jumper color="secondary lighten-2" to="vr" jump />
    </section>
    <!-- <section id="story" v-if="$vuetify.breakpoint.xsOnly">
      <story-search/>
    </section> -->
    <section id="vr">
      <HomeVr/>
      <scroll-to-jumper to="grid" color="secondary lighten-4" jump v-if="$vuetify.breakpoint.smAndUp"/>
    </section>
    <section id="grid">
      <EstatesHomeGrid/>
      <scroll-to-jumper to="how_it_works" color="secondary lighten-4" jump v-if="$vuetify.breakpoint.smAndUp"/>
    </section>
    <section id="how_it_works">
      <HowItWorks/>
      <!-- <scroll-to-jumper to="direct_search" color="secondary lighten-4" jump v-if="$vuetify.breakpoint.smAndUp"/> -->
      <scroll-to-jumper to="marketing" color="secondary lighten-4" jump v-if="$vuetify.breakpoint.smAndUp"/>
    </section>
    <!-- <section id="direct_search" >
      <DirectSearch/>
      <scroll-to-jumper to="marketing" color="secondary lighten-4" jump v-if="$vuetify.breakpoint.smAndUp"/>
    </section> -->
    <section id="marketing">
      <Marketing/>
      <scroll-to-jumper color="secondary lighten-4" jump v-if="$vuetify.breakpoint.smAndUp"/>
    </section>
    <!-- <section id="personal">
      <Personal/>
    </section> -->
    <v-btn aria-label="scroll to header" color="primary darken-1" fab small :class="['scroll-top', {show: showScrollTop}]" v-scroll-to="{el: '#home_header', offset: -60}">
      <v-icon class="icon-2xl">mdi-chevron-up</v-icon>
    </v-btn>
  </main>
</template>

<script type="text/javascript">
import HomeHeader from '@/app/modules/home/components/header'
import EstatesHomeGrid from '@/app/modules/home/components/estates-home-grid'
import HowItWorks from '@/app/modules/home/components/how-it-works'
// import Personal from '@/app/modules/home/components/personal'
import Marketing from '@/app/modules/home/components/marketing'
import HomeVr from '@/app/modules/home/components/home-vr'
// import DirectSearch from '@/app/modules/home/components/direct-search'
import ScrollToJumper from '@/common/components/scroll-to-jumper'
// import StorySearch from '@/app/common/components/story-search'

export default {
  name: 'Home',

  components: {
    // Personal,
    // DirectSearch,
    // StorySearch,
    HomeHeader,
    EstatesHomeGrid,
    HowItWorks,
    Marketing,
    HomeVr,
    ScrollToJumper
  },

  data () {
    return {
      showScrollTop: false
    }
  },

  methods: {
    onScroll () {
      this.showScrollTop = window.scrollY > window.window.innerHeight - 1
    }
  }
}

</script>

<style lang="scss">

  #grid,
  #vr,
  #how_it_works,
  // #direct_search,
  // #marketing,
  #home_header {
    position: relative;

    > *:first-child {
      min-height: 100%;
      margin: auto;
    }
  }

  // #direct_search,
  #vr,
  #how_it_works {

    @include md-and-up {
      height: 100vh;
    }
  }

  #direct_search,
  #vr,
  #grid,
  // #personal,
  #how_it_works {

    .section-header {
      text-align: center;
    }
  }

  #grid {

    @include lg-and-up {
      height: max(100vh, 800px);
    }
  }

  // #direct_search,
  // #marketing,
  #vr,
  #how_it_works {

    @include md-and-up {
      min-height: 100vh;
    }

    @include xs-only {
      height: auto;
    }
  }

  // #personal {
  //   position: relative;

  //   @include sm-and-up {
  //     height: auto;
  //   }
  // }

  .scroll-top {
    position: fixed;
    bottom: 25px;
    right: 19px;
    z-index: 1;
    opacity: 0;
    transform: scale(0);
    transition: all .2s;

    &.show {
      opacity: 1;
      transform: scale(1);
    }
  }

</style>


const routes = [
  {
    path: 'magazine',
    component: {
      render: (c) => c('router-view')
    },
    children: [
      {
        path: '',
        name: 'magazine',
        component: () => import(/* webpackChunkName: "magazine" */ '@/app/modules/magazine/views/index')
      },
      {
        path: ':slug',
        name: 'article',
        component: () => import(/* webpackChunkName: "magazine" */ '@/app/modules/magazine/views/magazine-article'),
        props: true,
        meta: {
          hideAlbi: true
        }
      },
      {
        path: '*',
        redirect: { name: 'notFound' }
      }
    ]
  }
]

export default routes


const routes = [
  {
    path: 'users',
    component: () => import(/* webpackChunkName: "users" */ './views/index'),
    children: [
      {
        path: 'me',
        name: 'profile',
        component: () => import('./views/user-profile'),
        meta: {
          userOnly: true
        }
      },
      {
        path: 'login',
        name: 'login',
        component: () => import('./views/login')
      },
      // {
      //   path: 'logout',
      //   name: 'logout',
      //   component: () => import('./views/logout')
      // },
      {
        path: 'reset-password/:token',
        name: 'resetPassword',
        component: () => import('./views/reset-password')
      }
    ]
  }
]

export default routes

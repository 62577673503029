
export const features = {
  safetyroom: false,
  grates: false,
  aircondition: false,
  alarm: false,
  basement: false,
  elevator: false,
  fitnessclub: false,
  garden: false,
  handicapaccessible: false,
  fittedkitchen: false,
  pool: false,
  storage: false,
  tenantclub: false,
  smarthome: false,
  walkincloset: false
}

export const featuresIcons = {
  aircondition: '',
  garden: '',
  elevator: '',
  basement: '',
  storage: '',
  grates: '',
  alarm: '',
  fittedkitchen: 'mdi-pot-mix-outline',
  pool: '',
  handicapaccessible: '',
  fitnessclub: '',
  tenantclub: '',
  smarthome: 'mdi-home-automation',
  walkincloset: 'mdi-hanger',
  safetyroom: ''
}

export const specificationsIcons = {
  rooms: 'mdi-bed-outline',
  balconies: '$balcony',
  baths: 'mdi-shower',
  parking: 'mdi-parking',
  parkings: 'mdi-parking',
  size: 'mdi-floor-plan',
  floor: 'mdi-stairs-up',
  stories: 'mdi-office-building',
  // kitchens: 'mdi-pot-mix-outline',
  house_committee: 'mdi-account-cash-outline',
  rate_tax: 'mdi-cash',
  water_hitter: 'mdi-water-boiler',
  age: 'mdi-timer-sand',
  evacuation_date: 'mdi-calendar-export',
  block_parcel: 'mdi-map-search-outline'
}

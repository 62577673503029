<template>

  <div :class="['estate-box', 'fill-height', 'elevation-2', {circle}]" ref="estate">
    <router-link :to="{name: 'estate', params: {slug: estate.slug}}"
                  class="flex"
                  :id="dataTrack()"
                  target="_blank">

      <v-img :src='imageUrl'
              aspect-ratio="2"
              height="100%"
              :max-height="maxHeight"
              class="estate">

        <v-icon color="primary darken-3" class="icon-2xl vr-icon" v-if="hasVR()">mdi-virtual-reality</v-icon>

        <v-layout v-if="!circle"
                  column
                  fill-height
                  class="content">

          <v-layout align-end>
            <v-flex class="estate-props">
              <h5 :class="['font-16 font-600 ellipsis', {'estate-props-sold': estate.status === 'sold'}]">{{fullAddress}}</h5>

              <div class="font-18 estate-details">
                <span>
                  {{estate.specifications.rooms}}
                  {{$t('estates.props.rooms')}}
                </span>

                <span>
                  {{estate.specifications.size}} {{$t('estates.form.size_suffix')}}
                </span>

                <span class="font-600">{{estate.price | currency}}</span>
              </div>
            </v-flex>
          </v-layout>
        </v-layout>

        <div class="estate-specifications" v-if="!circle">
          <div v-for="(prop) in propsWithValue()" class="margin-bottom-10 font-600" v-bind:key="prop">
            <v-icon class="icon-sm" color="bright">mdi-check-circle-outline</v-icon>
            <span>{{$t(`estates.props.${prop}`)}}</span>
          </div>
        </div>
      </v-img>
      <!-- <span>{{estate.address.street}}, {{estate.address.city}}</span> -->
    </router-link>
  </div>

</template>

<script type="text/javascript">
import EstateMixins from '@/common/mixins/estates/estate-mixins'
import { features } from '@/app/modules/estates/utils/estate-props'

export default {
  name: 'EstateBox',

  props: {
    estate: {
      type: Object,
      required: true
    },

    maxHeight: {
      type: String,
      required: false,
      default: ''
    },

    circle: Boolean
  },

  mixins: [EstateMixins],

  data () {
    // TODO: remove estate.images
    const img = this.estate.assets ? this.estate.assets.find((a) => a.asset_type === 'image') : null
    return {
      imageUrl: img ? img.url : this.estate.images[0],
      features
    }
  },

  methods: {
    getImageUrl () {
      // TODO: remove estate.images
      const img = this.estate.assets ? this.estate.assets.find((a) => a.asset_type === 'image') : null
      return this.$cloudinary.url(img ? img.provider_id : this.estate.images[0], {
        transformation: [{
          crop: this.circle ? '' : 'thumb',
          height: this.circle ? '100' : this.$refs.estate.clientHeight,
          width: this.circle ? '' : this.$refs.estate.clientWidth
        },
        {
          overlay: 'logos:logo',
          width: 50,
          gravity: 'south_east',
          x: 15,
          y: 70,
          opacity: 60
        }]
      })
    },

    hasVR () {
      return !!this.estate.assets.find((a) => a.asset_type === 'virtualTour')
    },

    dataTrack () {
      return this.estate.id.split('::')[1].replace(/-/g, '')
    },

    propsWithValue () {
      if (!this.estate.features) return []
      return this.estate.features.split(',').reduce((arr, f) => {
        if (this.features.hasOwnProperty(f)) arr.push(f)
        return arr
      }, []).slice(0, 5)
    }
  },

  created () {
    setTimeout(() => {
      this.imageUrl = this.getImageUrl()
    })
  },

  mounted () {
    // wait for component to be rendered
    setTimeout(() => {
      this.$mixpanel.track_links(`#${this.dataTrack()}`, 'estate_box_click', { page: this.$route.name, slug: this.estate.slug })
    }, 250)
  }
}
</script>

<style lang="scss">

.estate-box {
  display: flex;
  overflow: hidden;

  .v-image__image {
    transform: scale(1.011);
    transition: all .2s;
  }

  .content {
    color: $text_light;
  }

  .vr-icon {
    position: absolute;
    top: 5px;
    left: 5px;
    background-color: var(--v-bright-base);
    border-radius: 5px;
  }

  .estate-props {
    height: 60px;
    padding: 7px 10px 2px;
    background-image: linear-gradient(to top, $chip_dark 90%, transparent);

    h5 {
      position: relative;

      &:after {
        content: ' ';
        position: absolute;
        top: 3px;
        left: 0;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: var(--v-success-lighten1);
      }

      &.estate-props-sold:after {
        background-color: var(--v-warning-darken4);
      }
    }

    .estate-details {
      margin-top: -3px;

      span:not(:last-child):after {
        content: "";
        display: inline-block;
        position: relative;
        top: 4px;
        height: 18px;
        margin: 0 8px;
        border-left: 1px solid var(--v-secondary-lighten5);
      }
    }
  }

  .estate-specifications {
    position: absolute;
    top: 0;
    right: -100%;
    height: calc(100% - 55px);
    width: 100%;
    padding: 15px;
    background-image: linear-gradient(to left, $chip_light 35%, transparent);
    // box-shadow: 0 0 4px 4px $chip_light;
    transition: all .15s ease-out;
    color: #fff;
    text-shadow: 1px 1px 3px $chip_dark;

    .v-icon {
      margin-right: 5px;

      &:lang(he) {
        margin: 0 0 0 5px;
      }
    }

    &:empty {
      display: none;
    }
  }

  &.circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: relative;
  }

  &:not(.circle) {
    min-height: 250px;
    border-radius: 4px;
  }

  &:hover {

    .v-image__image {
      transform: scale(1.05);
    }

    .estate-specifications {
      right: 0;
    }
  }

  .map-wrapper & {
    margin: 5px 5px 5px 0; // align the box with map infowindow spaces
  }
}

</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
// import index from './index'
import Home from '@/app/modules/home/routes'
import Static from './static/routes'
import User from '@/app/modules/user/routes'
import Estates from '@/app/modules/estates/routes'
import Magazine from '@/app/modules/magazine/routes'
import store from './store'

var userFetched = false

store.dispatch('user/initUser')
Vue.use(VueRouter)

const appRoutes = [
  {
    path: '/',
    component: () => import('./index'),
    children: [
      ...Home,
      ...Estates,
      ...User,
      ...Static,
      ...Magazine,
      {
        path: '*',
        redirect: { name: 'notFound' }
      }
    ]
  },
  {
    path: '*',
    redirect: { name: 'notFound' }
  }
]

export const routes = appRoutes

const router = new VueRouter({ routes, mode: 'history' })

router.beforeEach(async (to, from, next) => {
  Vue.prototype.$mixpanel.track('page_view', { page: to.name, params: to.params })
  // TODO: https://router.vuejs.org/guide/advanced/scroll-behavior.html
  window.scrollTo(0, 0)
  // check if the route is for logged in users only
  if (to.matched.some(record => record.meta.userOnly)) {
    if (!store.getters['user/user'] && !userFetched) {
      // fetch the user in the initial load of the app
      await store.dispatch('user/initUser')
      userFetched = true
    }

    if (!store.getters['user/user']) {
      next({ path: '/' })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router

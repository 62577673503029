import UserService from './user-service'

const state = {
  user: null
}

const getters = {
  user: state => state.user,
  roles: state => state.user.roles,
  isAgent: state => state.user.roles.includes('agent'),
  isCustomer: state => state.user.roles.includes('customer'),
  isActive: state => state.user.status === 'active'
}

const mutations = {
  SET_USER: (state, user) => {
    state.user = user
  },

  CLEAR_USER: (state) => {
    state.user = null
  },

  SAVE_USER: (state, user) => {
    state.user = user
  }
}

const actions = {
  initUser: async ({ commit }) => {
    const user = await UserService.loadUser()
    if (user) {
      commit('SET_USER', user)
    }
  },

  login: async ({ commit }, params) => {
    const res = await UserService.login(params.email, params.password)
    commit('SET_USER', res)
  },

  logout: async ({ commit }) => {
    await UserService.logout()
    commit('CLEAR_USER')
    window.location.href = '/'
  },

  deleteUser: async ({ commit, state }) => {
    await UserService.deleteUser(state.user.id)
    commit('CLEAR_USER')
    window.location.href = '/'
  },

  saveUser: async ({ commit }, user) => {
    try {
      const res = await UserService.saveUser(user)
      commit('SAVE_USER', res)
    } catch (e) {
      throw e.data.errors
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

const isIntersecting = {
  data () {
    return {
      isIntersected: false
    }
  },

  methods: {
    onIntersect (entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.isIntersected = true
        this.$root.$emit('intersect', this.$options.name)
      }
    }
  }
}

export default isIntersecting

import { Api } from '@/common/services/api-service'

const signup = async (userParams) => {
  const res = await Api.post('/Users/signup', userParams)
  return res
}

const login = async (email, password) => {
  const res = await Api.post('/Users/Login', { user_name: email, password })
  return res
}

const loadUser = async (id) => {
  const param = id ? `/${id}` : ''
  try {
    const res = await Api.get(`/Users/loadUser${param}`)
    return res
  } catch (e) {
    return null
  }
}

const logout = async () => {
  await Api.get(`/Users/Logout`)
}

const saveUser = (user) => {
  const res = Api.put(`/Users/${user.id}`, user)
  return res
}

const changePassword = async (id, oldPassword, newPassword) => {
  const res = await Api.put(`/Users/changePassword/${id}`, { oldPassword, newPassword })
  return res
}

const isEmailExist = async (email) => {
  const res = await Api.get(`/Users/IsEmailExists/${encodeURIComponent(email)}`)
  return res
}

const resetPasswordRequest = async (email) => {
  const res = await Api.post('/Users/ResetPasswordRequest', { email })
  return res
}

const setPassword = async (id, password) => {
  const res = await Api.put(`/Users/SetPassword/${id}`, { password })
  return res
}

const deleteUser = async (id) => {
  const res = await Api.delete(`/Users/${id}`)
  return res
}

// const validateToken = async (userId) => {
//   // retutn true untill url will be created
//   const res = true // await Api.get(`/Users/validate_token`)
//   return res
// }

const userService = {
  signup,
  login,
  // validateToken,
  loadUser,
  logout,
  changePassword,
  saveUser,
  isEmailExist,
  setPassword,
  resetPasswordRequest,
  deleteUser
}

export default userService

import Home from './home'

const routes = [
  {
    path: '',
    name: 'home',
    component: Home
  }
]

export default routes

<template>
  <div :class="['home-header', {initial}]">
    <div v-intersect="onIntersect"/>
    <div :class="['home-header-w-map', { 'home-header-w-info': activeInfowindow }]" :style="{height: `${parallaxHight}px`}">
      <Map ref="map" :estates="estates" @infowindow="infowindow"/>
    </div>
   </div>
</template>

<script type="text/javascript">
import { mapActions, mapGetters } from 'vuex'
import intersecting from '@/common/mixins/intersecting-mixin'
import Map from '@/common/components/map'
import EstatesService from '@/app/modules/estates/estates-service'

export default {
  name: 'HomeHeader',

  components: {
    Map
  },

  mixins: [intersecting],

  data () {
    return {
      // windowHeight: window.innerHeight,
      initial: true,
      hpImageLoaded: false,
      estates: [],
      mapEstates: [],
      activeInfowindow: false
    }
  },

  computed: {
    ...mapGetters(['HPEntered']),

    parallaxHight () {
      // return this.windowHeight
      return window.innerHeight
    },

    headerImgUrl () {
      const h = window.innerHeight
      return this.$cloudinary.url('/app_images/home-header.webp', { height: Math.round(h * 1.2), crop: 'fit', quality: this.hpImageLoaded ? null : 10 })
    }
  },

  methods: {
    ...mapActions(['setHPEntered']),

    // pullLocationsData () {
    //   const addresses = []
    //   const locations = this.estates.map((e) => {
    //     if (e.address.location) {
    //       return {
    //         cords: {
    //           lng: e.address.location.lon,
    //           lat: e.address.location.lat
    //         },
    //         estate: e
    //       }
    //     }

    //     if (e.address.general_address) {
    //       addresses.push(e.address.general_address)
    //     }
    //     return null
    //   }).filter(Boolean)

    //   return { locations, addresses }
    // },

    async getHomeEstates () {
      return EstatesService.getEstatesByPromotePage({ limit: 25 })
    },

    async getMapEstates () {
      if (this.$refs.map) {
        const res = await EstatesService.searchEstates({ free_text: 'ישראל' })
        this.mapEstates = res.results
        this.$refs.map.clearMapMarkers()
        await this.$refs.map.clearMapMarkers()
        this.$refs.map.setMarkersByEstates(this.mapEstates)
      }
    },

    infowindow (e) {
      this.activeInfowindow = e
    }
  },

  async created () {
    // this event couse android chrome to rerender the header when openning the keyboard so the search lose focus and close the keyboard
    // window.addEventListener('resize', () => {
    //   this.windowHeight = window.innerHeight
    // })

    // load small quality image at initial load and the load the full image
    // setTimeout(() => {
    //   this.hpImageLoaded = true
    // }, 1500)

    // if (this.HPEntered) {
    //   this.initial = false
    // } else {
    //   setTimeout(() => {
    //     this.initial = false
    //     this.setHPEntered()
    //   }, 1250)
    // }
    this.estates = await this.getHomeEstates()
    this.estates.sort(() => 0.5 - Math.random()).slice(0, 5)
    // const locationsData = this.pullLocationsData()
  },

  async mounted () {
    await this.getMapEstates()
  }
}

</script>

<style lang='scss'>

  .home-header {

    &-w-map {
      position: relative;

      .home-header-content {
        position: absolute;
        top: 8%;
        left: 0;
        right: 0;
        border-radius: 8px;
        opacity: .95;
      }
    }

    &-w-info {

      .map-wrapper {
        z-index: 1;
        opacity: .9;
      }
    }
  }

</style>


const routes = [
  {
    path: 'estates',
    // component: () => import(/* webpackChunkName: "estates" */ '@/app/modules/estates/views/estates-index'),
    component: {
      render: (c) => c('router-view')
    },
    children: [
      {
        path: 'search',
        name: 'estates',
        component: () => import(/* webpackChunkName: "search" */ '@/app/modules/estates/views/estates'),
        meta: {
          hideFooter: true,
          hideAlbi: true,
          hideHeaderSearch: true
        }
      },
      {
        path: 'new',
        name: 'new_estate',
        component: () => import(/* webpackChunkName: "estates" */ '@/app/modules/estates/views/estate-new-edit'),
        meta: {
          userOnly: true,
          hideHeaderSearch: true
        }
      },
      {
        path: ':slug/edit',
        name: 'edit_estate',
        component: () => import(/* webpackChunkName: "estates" */ '@/app/modules/estates/views/estate-new-edit'),
        meta: {
          userOnly: true,
          hideHeaderSearch: true
        }
      },
      {
        path: ':slug',
        name: 'estate',
        component: () => import(/* webpackChunkName: "estate" */ '@/app/modules/estates/views/estate'),
        props: true
      },
      {
        path: '',
        redirect: { name: 'notFound' }
      },
      {
        path: '*',
        redirect: { name: 'notFound' }
      }
    ]
  }
]

export default routes

<template>
  <v-btn :color="color" icon :class="['scroll-btn', {jump: animate}]" @click="clicked" aria-label="scroll down">
    <v-icon class="icon-3xl">mdi-chevron-down-circle-outline</v-icon>
  </v-btn>
</template>

<script>
import VueScrollTo from 'vue-scrollto'

export default {

  name: 'ScrollToJumper',

  props: {
    to: {
      type: String,
      require: true
    },
    color: {
      type: String,
      default: 'primary'
    },
    jump: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      animate: this.jump
    }
  },

  methods: {
    clicked () {
      VueScrollTo.scrollTo(`#${this.to}`)
      this.$emit('click')
      this.animate = false
    }
  }
}

</script>

<style lang="scss" scoped>

  .scroll-btn {
    position: absolute;
    bottom: 15px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

</style>

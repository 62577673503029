import Vue from 'vue'
import '@/plugins/vue-cookies'
import config from './config.js'
import mapsService from '@/common/services/google-maps-service'
import router from './router'
import vuetifyPlugin from '@/plugins/vuetify'
import i18nPlugin from '@/plugins/i18n'
import store from './store'
import '@/plugins/vue-scroll-to'
import '@/common/filters'
import '@/plugins/vue-meta'
import '@/plugins/vue-social-sharing'
import '@/plugins/cloudinary-vue'
import '@/plugins/recaptcha'
import '@/plugins/vue-moment'
import '@/plugins/load-script'
import '@/plugins/mixpanel'
import '@/common/mixins/global-mixins'
import '@/common/mixins/mixpanel-tracking'
// import '@/plugins/svg-sprites'
import '@/registerServiceWorker'
import '@/common/components'
import cloudinaryCore from '@/plugins/cloudinary-core'
import VueLazyLoad from 'vue-lazyload'
import App from './App.vue'

Vue.prototype.setNomeralLocal(config.locale.lang)
const maps = mapsService(config)
const vuetify = vuetifyPlugin(config)
const i18n = i18nPlugin(config)

Vue.prototype.$env = process.env.NODE_ENV
Vue.prototype.$scope = 'app'
Vue.prototype.$config = config
Vue.prototype.$cloudinary = cloudinaryCore
Vue.prototype.$maps = maps

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  VueLazyLoad,
  render: h => h(App)
}).$mount('#app')

require('@/assets/app.scss')

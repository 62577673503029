<template>
  <v-row align="center" class="home-vr text-center colored-section" v-intersect.once="onIntersect">
    <v-col>
      <transition name="slide-fade-down">
        <div class="section-header" v-if="isIntersected">
          <h3 class="font-36 font-600 margin-bottom-20">{{ $t('home_page.vr.title') }}</h3>

          <div class="font-18">
            <vue-markdown>{{ $t('home_page.vr.text') }}</vue-markdown>
          </div>
        </div>
      </transition>

      <transition name="slide-fade-up">
          <cld-video muted="true" playsinline controls autoplay publicId="videos/v-tour" width="100%" loop v-if="isIntersected"/>
      </transition>
    </v-col>
  </v-row>
</template>

<script>
import intersecting from '@/common/mixins/intersecting-mixin'

export default {

  name: 'HomeVr',

  mixins: [intersecting],

  data () {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
  .home-vr {

    video {
      max-width: 900px;
      margin-top: 30px;
    }
  }
</style>

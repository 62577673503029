<template>
  <v-row class="map-wrapper" no-gutters>
    <v-flex class="map" ref="map"/>
    <div v-if="bubbleEstate" style="position:fixed; top: -10000px;">
      <estate-box class="estate-box-map-buble" :estate="bubbleEstate" ref="bubble" :key="bubbleEstate.id"/>
    </div>
  </v-row>
</template>

<script type="text/javascript">
import EstateBox from '@/app/modules/estates/components/estate-box'

export default {
  name: 'Map',

  props: {
    addresses: {
      type: [Array, String],
      default: ''
    },
    locations: {
      type: Array,
      default: () => []
    },
    zoom: {
      type: Number,
      default: 16
    },
    static: Boolean
  },

  components: {
    EstateBox
  },

  data () {
    return {
      map: {},
      currentLocation: { lat: 31.771959, lng: 35.217018 },
      markers: [],
      bounds: null,
      geocoder: null,
      bubbleEstate: null
    }
  },

  methods: {
    getCurrentlocation () {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.currentLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          }
        })
      }
    },

    async setMarkersByAddress (addresses) {
      if (!addresses || !addresses.length) return

      while (!this.geocoder) { // wait for geocoder to load
        await new Promise(resolve => setTimeout(resolve, 1000))
      }

      this.geocoder.geocode({ address: addresses.toString() }, (results, status) => {
        if (status === 'OK') {
          var resultsLocation = []
          var location, lat, lng
          results.forEach((result) => {
            location = result.geometry.location
            lat = location.lat()
            lng = location.lng()

            resultsLocation.push({
              cords: {
                lat,
                lng
              },
              address: result.formatted_address
            })
          })
          this.setMarkers(resultsLocation)
        }
      })
    },

    async setMarkersByEstates (estates = []) {
      if (!estates.length) return

      while (!this.geocoder) { // wait for geocoder to load
        await new Promise(resolve => setTimeout(resolve, 1000))
      }

      const resultsLocation = []

      estates.filter((e) => e.status === 'active').forEach((e) => {
        if (e.address.location) {
          resultsLocation.push({
            cords: {
              lat: e.address.location.lat,
              lng: e.address.location.lon
            },
            address: e.address.general_address,
            estate: e
          })
        } else {
          this.geocoder.geocode({ address: e.address.general_address }, (results, status) => {
            if (status === 'OK') {
              var location, lat, lng
              results.forEach((result) => {
                location = result.geometry.location
                lat = location.lat()
                lng = location.lng()

                resultsLocation.push({
                  cords: {
                    lat,
                    lng
                  },
                  address: result.formatted_address,
                  estate: e
                })
              })
            }
          })
        }
      })

      const t = setTimeout(() => {
        if (!resultsLocation.length) return
        this.setMarkers(resultsLocation)
        clearTimeout(t)
      }, 500)
    },

    setMarkers (pos) {
      if (!pos.length) return

      let infowindow = null
      if (pos.filter((p) => p.estate).length) {
        infowindow = this.$maps.InfoWindow({
          ariaLabel: 'Uluru',
          zIndex: 9999999
        })
        this.$maps.addListener(this.map, 'click', (e) => {
          this.$emit('map-clicked')
          infowindow.close()
          this.$emit('infowindow', false)
        })
      }
      pos.forEach((p) => {
        this.setMarker(p, infowindow)
      })
      this.adjustBounds()
    },

    setMarker (pos, infowindow = null) {
      if (!pos) return
      const marker = this.$maps.Marker({
        position: pos.cords,
        map: this.map,
        title: pos.address,
        animation: this.$maps.Animation('DROP'),
        icon: require('@/assets/images/logo-marker-bw.png')
      })

      if (infowindow) {
        marker.addListener('click', () => {
          this.bubbleEstate = pos.estate
          setTimeout(() => {
            infowindow.setContent(this.$refs.bubble.$el)
            infowindow.open({
              anchor: marker,
              map: this.map
            })
            this.$emit('infowindow', true, this.bubbleEstate)
            this.$mixpanel.track('map', {
              action: 'info_window',
              page: this.$route.name,
              estate: this.bubbleEstate.id,
              slug: this.bubbleEstate.slug
            })
          })
        })
      }
      this.markers.push(marker)
      this.bounds.extend(pos.cords)
    },

    adjustBounds () {
      if (this.markers.length === 1) {
        // reduce the zoom in case of one marker in the map
        this.bounds.extend({ lat: this.markers[0].position.lat() + 0.01, lng: this.markers[0].position.lng() + 0.01 })
        this.bounds.extend({ lat: this.markers[0].position.lat() - 0.01, lng: this.markers[0].position.lng() - 0.01 })
      }
      this.map.fitBounds(this.bounds)
    },

    async clearMapMarkers () {
      this.bounds = await this.$maps.LatLngBounds()
      this.markers.forEach((m) => {
        m.setMap(null)
      })
      this.markers = []
    },

    async loadMap () {
      this.map = await this.$maps.Maps(this.$refs.map, {
        zoom: this.zoom,
        center: this.currentLocation,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        controlSize: 32,
        draggable: !this.static,
        styles: [{
          featureType: 'poi.business',
          stylers: [{ visibility: 'off' }]
        }]
      })
    }
  },

  async mounted () {
    this.getCurrentlocation()
    if (this.location && this.locations.length) {
      this.setMarkers(this.locations)
    } else if (this.addresses) {
      this.setMarkersByAddress(this.addresses)
    }
    await this.loadMap()
    this.bounds = await this.$maps.LatLngBounds()
    this.geocoder = await this.$maps.Geocoder()
  }
}

</script>

<style lang="scss">

  .map-wrapper {
    position: relative;
    min-height: 400px;
    height: 100%;
    border: 1px solid var(--v-bright-darken1);

    .estate-box-map-buble {

      .estate-details {

        @include xs-only {
          font-size: $font-sm;
        }
      }
    }
  }
</style>

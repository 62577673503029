import { Api } from '@/common/services/api-service'
import store from '@/app/store'

const convertFeaturesToLowerCase = (estate) => {
  if (!estate.features) return
  estate.features = estate.features.toLowerCase().replace(/\s/g, '')
}

const getEstates = async (params = 'Pagination.Page=1&Pagination.Limit=25') => {
  if (store.getters['isBot']) {
    return []
  }

  try {
    const res = await Api.get(`/estates?${params}`)
    convertFeaturesToLowerCase(res)
    return res
  } catch (e) {
    console.error(e)
  }
  return []
}

const getEstatesByPromotePage = async (params) => {
  if (store.getters['isBot']) {
    return []
  }

  params = Object.assign({ pageType: 'home', page: '1', limit: '5' }, params)

  const query = `PromotPage=${params.pageType}&Pagination.Page=${params.page}&Pagination.Limit=${params.limit}`

  try {
    const res = await Api.get(`/estates/EstatesByPromotePage?${query}`)
    res.forEach((estate) => {
      convertFeaturesToLowerCase(estate)
    })
    return res
  } catch (e) {
    console.error(e)
  }
  return []
}

const getUserEstates = async (page = 1, limit = 20) => {
  if (store.getters['isBot']) {
    return []
  }
  const userId = store.getters['user/user'].id

  try {
    const res = await Api.get(`/estates/User/${userId}?page=${page}&limit=${limit}`)
    if (res.length) {
      res.forEach((estate) => {
        convertFeaturesToLowerCase(estate)
      })
    }
    return res
  } catch (e) {
    console.error(e)
  }
  return []
}

const searchEstates = async (params = {}) => {
  if (store.getters['isBot']) {
    return []
  }

  try {
    const res = await Api.post(`/estates/search`, params)
    if (res.results.length) {
      res.results.forEach((estate) => {
        convertFeaturesToLowerCase(estate)
      })
    }
    return res
  } catch (e) {
    console.error(e)
  }
  return []
}

const searchEstatesCount = async (params = {}) => {
  if (store.getters['isBot']) {
    return []
  }

  try {
    const res = await Api.post(`/estates/search/address/count`, params)
    return res
  } catch (e) {
    console.error(e)
  }
  return []
}

const getEstate = async (slug, edit = false) => {
  if (store.getters['isBot']) {
    return []
  }

  let url = '/estates'

  if (/(estate::)/.test(slug.toLowerCase())) {
    url += `/${slug}`
    url += edit ? '/edit' : ''
  } else {
    url += '/slug'
    url += edit ? '/edit' : ''
    url += `?slug=${slug}`
  }

  const res = await Api.get(url)
  convertFeaturesToLowerCase(res)
  return res
}

const saveEstate = async (editedEstate) => {
  const method = editedEstate.id ? 'put' : 'post'
  const id = editedEstate.id || ''
  const res = await Api[method](`/estates/${id}`, editedEstate)
  convertFeaturesToLowerCase(res)
  return res
}

// const updateEstate = async (editedEstate) => {
//   const res = await Api.post('/estates', editedEstate)
//   return res
// }

const saveAssets = async (id, assets) => {
  try {
    const res = await Api.post(`/estateAssets/${id}`, assets)
    if (res) {
      convertFeaturesToLowerCase(res)
      return res
    }
  } catch (e) {
    return e
  }
}

const deleteAssets = async (id, assets) => {
  const res = await Api.delete(`/estateAssets/${id}`, { data: assets })
  if (res) {
    convertFeaturesToLowerCase(res)
    return res
  }
}

const sendContactRequest = async (id, contactRequest) => {
  try {
    const res = await Api.post(`/estates/contactRequest/${id}`, contactRequest)
    if (res) {
      convertFeaturesToLowerCase(res)
      return res
    }
  } catch (e) {
    return e
  }
}

const editContactRequest = async (id, contactRequest) => {
  try {
    const res = await Api.put(`/estates/contactRequest/${id}`, contactRequest)
    if (res) {
      convertFeaturesToLowerCase(res)
      return res
    }
  } catch (e) {
    return e
  }
}

const estatesService = {
  getEstate,
  getUserEstates,
  getEstates,
  saveEstate,
  saveAssets,
  deleteAssets,
  searchEstates,
  getEstatesByPromotePage,
  sendContactRequest,
  editContactRequest,
  searchEstatesCount
}

export default estatesService

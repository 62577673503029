import Vue from 'vue'

export default Vue.mixin({

  data () {
    return {
      oneTimeEvents: []
    }
  },

  methods: {
    // share and track changes on eary jun 2023 from estate_share to share and estate_contact to contact
    trackShare (network) {
      this.$mixpanel.track('share', { shared_on: network, page: this.$route.name, page_params: this.$route.params })
    },

    trackContact (method) {
      this.$mixpanel.track('contact', { method, page: this.$route.name, page_params: this.$route.params })
    },

    trackClick (event = null, props = null) {
      if (!event) {
        console.error('trackClick - no event to track')
      }
      this.$mixpanel.track(event, props)
    },

    trackOnce (event = null, props = null) {
      if (!event) {
        console.error('trackOnce - no event to track')
      }
      if (this.oneTimeEvents.includes([event, props].toString())) return

      this.oneTimeEvents.push([event, props].toString())

      this.trackClick(event, props)
    }
  },

  beforeDestroy () {
    this.oneTimeEvents = []
  }
})

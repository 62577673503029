<template>
  <div class="lets-sale padding-20 text-center">
    <h3 class="font-36 font-600 margin-bottom-50">אנחנו היתרון השיווקי שלך</h3>

    <v-row no-gutters v-intersect.once="onIntersect">
      <transition name="slide-fade-down">
        <v-col class="padding-tb-15" cols="12" sm="3" offset-sm="2" v-if="isIntersected">
          <v-icon class="icon-3xl">mdi-graphql</v-icon>
          <h6 class="font-18 font-600 margin-top-10">{{ $t('marketing.items')[0].title }}</h6>
          <vue-markdown>{{ $t('marketing.items')[0].text }}</vue-markdown>
        </v-col>
      </transition>

      <transition name="slide-fade-down">
        <v-col class="padding-tb-15" cols="12" sm="3" offset-sm="2" v-if="isIntersected">
          <v-icon class="icon-3xl">mdi-google-analytics</v-icon>
          <h6 class="font-18 font-600 margin-top-10">{{ $t('marketing.items')[1].title }}</h6>
          <vue-markdown>{{ $t('marketing.items')[1].text }}</vue-markdown>
        </v-col>
      </transition>

      <transition name="slide-fade">
        <v-col class="padding-tb-15" cols="12" sm="3" offset-sm="2" v-if="isIntersected">
          <v-icon class="icon-3xl">mdi-map-marker-path</v-icon>
          <h6 class="font-18 font-600 margin-top-10">{{ $t('marketing.items')[2].title }}</h6>
          <vue-markdown>{{ $t('marketing.items')[2].text }}</vue-markdown>
        </v-col>
      </transition>

      <transition name="slide-fade-reverse">
        <v-col class="padding-tb-15" cols="12" sm="3" offset-sm="2" v-if="isIntersected">
          <span class="font-36 font-600">AI</span>
          <h6 class="font-18 font-600 margin-top-10">{{ $t('marketing.items')[3].title }}</h6>
          <vue-markdown>{{ $t('marketing.items')[3].text }}</vue-markdown>
        </v-col>
      </transition>

      <transition name="slide-fade-up">
        <v-col class="padding-tb-15" sm="3" offset-sm="2" v-if="isIntersected">
          <v-icon class="icon-3xl">mdi-database</v-icon>
          <h6 class="font-18 font-600 margin-top-10">{{ $t('marketing.items')[4].title }}</h6>
          <vue-markdown>{{ $t('marketing.items')[4].text }}</vue-markdown>
        </v-col>
      </transition>

      <transition name="slide-fade-up">
        <v-col class="padding-tb-15" cols="12" sm="3" offset-sm="2" v-if="isIntersected">
          <v-icon class="icon-3xl">mdi-target-account</v-icon>
          <h6 class="font-18 font-600 margin-top-10">{{ $t('marketing.items')[5].title }}</h6>
          <vue-markdown>{{ $t('marketing.items')[5].text }}</vue-markdown>
        </v-col>
      </transition>
    </v-row>

    <v-row>
      <v-col cols="12" sm="8" offset-sm="2">
        <h6 class="font-24 margin-bottom-10">בואו לשווק איתנו את הבית מהבית</h6>
        <quick-contact :inline="$vuetify.breakpoint.smAndUp" form-name="home_maketing">
          <template slot="hidden-fields">
            <input type="hidden" name="subject" value="מכירה">
            <input type="hidden" name="message" :value="message">
          </template>
          <template v-slot:content>
            <div class="text-center font-18 font-600">
              <vue-markdown>{{$t("quick_contact.by_phone")}}</vue-markdown>
              {{ $t("common.or") }}
              <a :href="'0774110137' | whatsapp($t('work_with_us.whatsapp_message', { message }))" target="_blank">
                {{ $t("quick_contact.or_whatsapp") }}
                <v-icon class="icon-lg" color="var(--v-whatsapp)">mdi-whatsapp</v-icon>
              </a>
            </div>
          </template>
        </quick-contact>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import intersecting from '@/common/mixins/intersecting-mixin'
import quickContact from '@/common/components/quick-contact'

export default {

  name: 'Marketing',

  components: {
    quickContact
  },

  mixins: [intersecting],

  data () {
    return {
      message: 'שלוםת מעוניין/ת לשווק את הבית בעזרתכם.'
    }
  }

}
</script>

<style lang="scss" scoped>

  .lets-sale {
    overflow: hidden;

    span {
      line-height: 36px;
      color: rgba(0, 0, 0, 0.54);
    }

    .quick-contact {
      background-color: #f9f7f6;
    }
  }
</style>

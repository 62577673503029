
const rules = {
  data () {
    return {
      rules: {
        required: value => !!value || this.$t('common.form_validations.errors.required'),
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || !value || this.$t('common.form_validations.errors.email')
        },
        number: value => {
          const pattern = /^[0-9]+$/
          return pattern.test(value) || !value || this.$t('common.form_validations.errors.numbers')
        },
        mobile: value => {
          // TODO: this is for IL number only!!
          const pattern = /^(05)[0-9]{8}$/
          return pattern.test(value) || !value || this.$t('common.form_validations.errors.cell_phone')
        },
        password: value => {
          // const pattern = /[a-z]+[A-Z]+[0-9]/
          return (/[a-z]/.test(value) && /[A-Z]/.test(value) && /[0-9]/.test(value)) || !value || this.$t('common.form_validations.errors.password')
        }
      }
    }
  }
}

export default rules
